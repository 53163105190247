import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
  type: 'error',
  message: '',
};

export const toastAlertSlice = createSlice({
  name: 'toastAlert',
  initialState,
  reducers: {
    showSuccessToast: (state, action) => {
      state.type = 'success';
      state.message = action.payload.message;
      state.isVisible = true;
    },
    showErrorToast: (state, action) => {
      state.type = 'error';
      state.message = action.payload.message;
      state.isVisible = true;
    },
    hideAlertModal: (state, action) => {
      state.type = initialState.type;
      state.message = initialState.message;
      state.isVisible = initialState.isVisible;
    },
  },
});

export const { showErrorToast, showSuccessToast, hideAlertModal } = toastAlertSlice.actions;

export default toastAlertSlice.reducer;
