import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchImportAndActivateCards = createAsyncThunk(
  'cards/fetchImportAndActivateCards',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/import-register/cards', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const saveImportAndActivateCards = createAsyncThunk(
  'cards/saveImportAndActivateCards',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post('/import-register/cards/save', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const importAndActivateCardsSlice = createSlice({
  name: 'importAndActivateCards',
  initialState: {
    importAndActivateCards: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetImportAndActivateCards: (state) => {
      state.importAndActivateCards = {};
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchImportAndActivateCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchImportAndActivateCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importAndActivateCards = action.payload;
      })
      .addCase(fetchImportAndActivateCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(saveImportAndActivateCards.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(saveImportAndActivateCards.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.importAndActivateCards = action.payload;
      })
      .addCase(saveImportAndActivateCards.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetImportAndActivateCards } = importAndActivateCardsSlice.actions;

export default importAndActivateCardsSlice.reducer;
