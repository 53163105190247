import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { cellStatus, driverBillingStatuses } from 'utils/DataGrid';
import { stringCompare } from 'utils/helpers';

interface IProps {
  accessorKey?: string;
}

export const useBillingColumn = ({ accessorKey = 'invoiceStatus' }: IProps) => {
  const { t } = useTranslation();

  const allUniqueStatuses = useMemo<string[]>(
    () => [...new Set(Object.values(driverBillingStatuses).map((item) => t(item.name)))].sort(stringCompare),
    [t]
  );

  const billingStatusColumn = {
    accessorKey,
    accessorFn: (row: any) => t(`billing.status.${row?.status}`),
    header: t('global.label.status'),
    Cell: ({ row, cell }: any) => cellStatus(driverBillingStatuses, row.original.invoiceStatus),
    filterVariant: 'multi-select',
    filterSelectOptions: allUniqueStatuses,
  };

  return {
    billingStatusColumn,
  };
};
