import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { stringCompare } from 'utils';
import { cellChargingSessionsStatus, chargingHistoryStatuses, chargingSessionStatus } from 'utils/DataGrid';

export const useChargingHistoryColumn = () => {
  const { t } = useTranslation();
  const allUniqueChargingHistoryStatuses = useMemo<string[]>(
    () => [...new Set(Object.values(chargingHistoryStatuses).map((item) => t(item.name)))].sort(stringCompare),
    []
  );

  const statusColumn = {
    accessorFn: (row: any) => chargingSessionStatus(row?.status, t),
    id: 'chargingSessionStatus',
    header: t('global.header.status'),
    Cell: ({ row, cell }: any) =>
      cellChargingSessionsStatus(chargingHistoryStatuses, row?.original?.status, row?.original?.disconnectedTime),
    filterVariant: 'multi-select',
    filterSelectOptions: allUniqueChargingHistoryStatuses,
  };

  return {
    statusColumn,
  };
};
