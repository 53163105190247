import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from '../../../utils';

export const fetchErrorLogs = createAsyncThunk(
  'analytics/fetchErrorLogs',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/stations/error-logs${queryParameters}`)
        : await axiosRequest.get('/stations/error-logs');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const errorLogsSlice = createSlice({
  name: 'errorLogs',
  initialState: {
    errorLogs: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    addErrorLogs: (state, action) => {
      state.errorLogs.push(action.payload);
    },
    clearErrorLogs: (state, action) => {
      state.errorLogs = [];
      state.status = STATUS_IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchErrorLogs.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchErrorLogs.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.errorLogs = action.payload;
      })
      .addCase(fetchErrorLogs.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { addErrorLogs, clearErrorLogs } = errorLogsSlice.actions;

export default errorLogsSlice.reducer;
