import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { stringCompare } from 'utils/helpers';
import { allCards, cellStatus } from 'utils/DataGrid';
import { CARD_STATUS_NOT_ACTIVATED, getCardStatusLabel } from 'utils/models/card';
import { theme } from 'themes/defaultTheme';

export const useCardColumn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleActivateCard = (event: any, contractId: string) => {
    event.stopPropagation();
    navigate(`/authorizations/activate/single?contractId=${contractId}`);
  };

  const allUniqueStatuses = useMemo<string[]>(
    () => [...new Set(Object.values(allCards).map((item) => t(item.name)))].sort(stringCompare),
    [t]
  );

  const cardStatusColumn = {
    accessorKey: 'status',
    header: t('global.label.status'),
    accessorFn: (row: any) => t(getCardStatusLabel(row?.status)),
    Cell: ({ row, cell }: any) => {
      if (cell.getValue() === t(getCardStatusLabel(CARD_STATUS_NOT_ACTIVATED))) {
        return (
          <Tooltip title={t('card.activate.title')}>
            <IconButton
              sx={{
                border: `1.5px solid ${theme.palette.primary.main}`,
                width: 28,
                height: 28,
              }}
              onClick={(event) => handleActivateCard(event, row?.original?.contractId)}
            >
              <AddIcon sx={{ fontSize: 18, color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
        );
      }
      return cellStatus(allCards, row.original.status);
    },
    filterVariant: 'multi-select',
    filterSelectOptions: allUniqueStatuses,
  };

  return {
    cardStatusColumn,
  };
};
