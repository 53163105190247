export const formatWithSpace = (text: string, previousText: string, spaceIndexes: number[]): string => {
  const currentValue = text;
  let updatedValue = text;

  spaceIndexes.forEach((index) => {
    if (currentValue.length === index) {
      updatedValue = `${currentValue} `;
    }

    if (previousText.length === index + 1 && currentValue.length === index) {
      updatedValue = currentValue.slice(0, -1);
    }
  });

  spaceIndexes.forEach((index) => {
    if (updatedValue.length >= index + 1) {
      if (updatedValue[index] !== ' ') {
        updatedValue = `${updatedValue.substring(0, index)} ${updatedValue.substring(index, updatedValue.length)}`;
      }
    }
  });

  return updatedValue;
};
