import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
};

export const windowLoaderSlice = createSlice({
  name: 'windowLoader',
  initialState,
  reducers: {
    showWindowLoader: (state, action) => {
      state.isVisible = true;
    },
    hideWindowLoader: (state, action) => {
      state.isVisible = false;
    },
  },
});

export const { showWindowLoader, hideWindowLoader } = windowLoaderSlice.actions;

export default windowLoaderSlice.reducer;
