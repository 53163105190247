import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';

export const fetchStationsChargingSessions = createAsyncThunk(
  'station/fetchStationsChargingSessions',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/transactions${queryParameters}`)
        : await axiosRequest.get('/transactions');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const stationsChargingSessionsSlice = createSlice({
  name: 'stationsChargingSessions',
  initialState: {
    stationsChargingSessions: [],
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    clearStationsChargingSessions: (state, action) => {
      state.stationsChargingSessions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationsChargingSessions.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationsChargingSessions.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationsChargingSessions = action.payload;
      })
      .addCase(fetchStationsChargingSessions.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { clearStationsChargingSessions } = stationsChargingSessionsSlice.actions;

export default stationsChargingSessionsSlice.reducer;
