import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Dialog } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { hideAlertPopup } from 'reduxApp/slices/ui/popupAlertSlice';
import { CustomInputText } from 'components/common';

const width = 600;

export function PopupAlert({}) {
  const { isVisible, type = 'error', message } = useSelector((state) => state.popupAlert);
  const dispatch = useDispatch();

  if (!isVisible) {
    return null;
  }

  return (
    <Dialog
      onClose={() => {
        dispatch(hideAlertPopup({}));
      }}
      open={isVisible}
      sx={{ color: '#fff', zIndex: (t) => t.zIndex.modal + 2 }}
    >
      <Alert
        severity={type}
        variant="outlined"
        sx={{
          width,
          maxWidth: '90vw',
          pb: 1,
          backgroundColor: '#fff',
        }}
        onClose={() => {
          dispatch(hideAlertPopup({}));
        }}
      >
        <Typography sx={{ textAlign: 'center' }}>{type[0].toUpperCase() + type.substring(1, type.length)}</Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <CustomInputText
            value={message}
            multiline
            rows={8}
            sx={{
              width: width - 110,
              maxWidth: 'calc(90vw - 110px)',
            }}
          />
        </Box>
      </Alert>
    </Dialog>
  );
}
