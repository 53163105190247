import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { saveToLocalStorage } from 'utils';
import { axiosRequest } from 'api/axiosRequest';
import { fetchSidebarMenu } from 'reduxApp/slices/sidebarMenuSlice';
import { countUnreadNotifications } from 'reduxApp/slices/notificationsSlice';
import {
  IApiGenericCallbackPayload,
  AsyncThunkConfig,
  LoginUserPayload,
  ReduxStatusEnum,
  ResetAndLoginUserPayload,
  UpdateUserPayload,
  IUser,
  UserWithToken,
} from '../../../types';

interface IInitialState {
  user: IUser;
  token: string | null;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

export const fetchMe = createAsyncThunk<IUser, { userId: string }, AsyncThunkConfig>(
  'user/fetchMe',
  async ({ userId }, getThunkAPI) => {
    try {
      const response = await axiosRequest.get(`/users/${userId}`);

      const existingStateUser = getThunkAPI.getState().user;

      const newUser = {
        ...existingStateUser,
        user: {
          ...existingStateUser.user,
          ...response.data,
        },
      };
      saveToLocalStorage('user', newUser, 60 * 24 * 365);

      return newUser.user;
    } catch (e) {
      return getThunkAPI.rejectWithValue(e);
    }
  }
);

export const updateUser = createAsyncThunk<
  Partial<IUser>,
  IApiGenericCallbackPayload<UpdateUserPayload> & { userId: string },
  AsyncThunkConfig
>('user/updateUser', async ({ userId, onSuccessCallback, body, onErrorCallback }, { rejectWithValue }) => {
  try {
    if (!body) {
      return rejectWithValue('No body');
    }
    await axiosRequest.put(`/users/${userId}`, body);

    onSuccessCallback && onSuccessCallback();
    return { ...body };
  } catch (e) {
    onErrorCallback && onErrorCallback();
    return rejectWithValue(e);
  }
});

export const deleteUserAction = createAsyncThunk<
  { userId: string },
  IApiGenericCallbackPayload<UpdateUserPayload> & { userId: string },
  AsyncThunkConfig
>('user/deleteUserAction', async ({ userId, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
  try {
    await axiosRequest.delete(`/users/${userId}`);

    onSuccessCallback && onSuccessCallback();
    return { userId };
  } catch (e) {
    onErrorCallback && onErrorCallback();
    return rejectWithValue(e);
  }
});

export const login = createAsyncThunk<UserWithToken, LoginUserPayload, AsyncThunkConfig>(
  'user/login',
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosRequest.post('/login', { email, password, rememberMe: 1 });
      const user = {
        token: response.data.token,
        user: { ...response.data.user },
      };

      await saveToLocalStorage('user', user, 60 * 24 * 365);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchSidebarMenu({})); // await menu data to settle // maybe the same to notifications?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(countUnreadNotifications({}));
      dispatch(fetchMe({ userId: response.data.user.id }));

      return user;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetAndLogin = createAsyncThunk<UserWithToken, ResetAndLoginUserPayload, AsyncThunkConfig>(
  'user/resetAndLogin',
  async ({ token, password }, { rejectWithValue, dispatch }) => {
    try {
      const options = {} as { headers?: { Authorization: string } };

      if (token) {
        options.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      const response = await axiosRequest.put(`/users/password`, { password }, { ...options });
      const user = {
        token: response.data.token,
        user: { ...response.data.user },
      };
      await saveToLocalStorage('user', user, 60 * 24 * 365);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchSidebarMenu({})); // await menu data to settle // maybe the same to notifications?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(countUnreadNotifications({}));
      dispatch(fetchMe({ userId: response.data.user.id }));

      return user;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const logout = createAsyncThunk<IInitialState, void, AsyncThunkConfig>('user/logout', async () => {
  await saveToLocalStorage('user', null, -1);
  return {} as IInitialState;
});

export const reauth = createAsyncThunk<UserWithToken, UserWithToken>('user/reauth', async (user) => {
  return user;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {} as IUser,
    token: null,
    status: ReduxStatusEnum.IDLE,
    error: null,
  } as IInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchMe.fulfilled, (state, action) => {
        state.user = action.payload;
      })

      // update user
      .addCase(updateUser.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // delete user
      .addCase(deleteUserAction.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(deleteUserAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // login
      .addCase(login.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.token = action.payload?.token;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // resetAndLogin
      .addCase(resetAndLogin.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(resetAndLogin.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.token = action.payload?.token;
        state.user = action.payload.user;
      })
      .addCase(resetAndLogin.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // logout
      .addCase(logout.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.user = {} as IUser;
        state.token = null;
        window.location.replace('/auth/login');
      })

      // reauth
      .addCase(reauth.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.user = action.payload.user;
        state.token = action.payload.token;
      });
  },
});

export default userSlice.reducer;
