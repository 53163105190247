import React from 'react';
import { theme } from 'themes/defaultTheme';
import {
  CONNECTOR_STATUS_AVAILABLE,
  CONNECTOR_STATUS_CHARGING,
  CONNECTOR_STATUS_ERROR,
  CONNECTOR_STATUS_FAULTED,
  CONNECTOR_STATUS_FINISHING,
  CONNECTOR_STATUS_OFFLINE,
  CONNECTOR_STATUS_PREPARING,
  CONNECTOR_STATUS_RESERVED,
  CONNECTOR_STATUS_SUSPENDEDEV,
  CONNECTOR_STATUS_SUSPENDEDEVSE,
  CONNECTOR_STATUS_UNAVAILABLE,
} from './connector';
import { capitalizeFirstLetter } from '../helpers';
import { ReactComponent as AvailableIcon } from '../../assets/images/icons/map/available.svg';
import { ReactComponent as ChargingIcon } from '../../assets/images/icons/map/charging.svg';
import { ReactComponent as OccupiedIcon } from '../../assets/images/icons/map/occupied.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/icons/map/error.svg';
import { ReactComponent as OfflineIcon } from '../../assets/images/icons/map/offline.svg';

export const STATUS_AVAILABLE = 'Available';
export const STATUS_UNAVAILABLE = 'Unavailable';
export const STATUS_IN_USE = 'In use';
export const STATUS_NOT_ACTIVATED = 'Not activated';
export const STATUS_ERROR = 'Error';
export const STATUS_OFFLINE = 'Offline';

export const stationStatusesObject = {
  [STATUS_AVAILABLE]: {
    label: STATUS_AVAILABLE,
    color: theme.palette.customColors.statuses.green,
    icon: <AvailableIcon />,
    connectorStatuses: [CONNECTOR_STATUS_AVAILABLE],
  },
  // Unavailable: {
  //   label: STATUS_UNAVAILABLE,
  //   color: theme.palette.customColors.statuses.grey,
  //   icon: <OfflineIcon />,
  // },
  [STATUS_IN_USE]: {
    label: STATUS_IN_USE,
    color: theme.palette.customColors.statuses.blue,
    icon: <ChargingIcon />,
    connectorStatuses: [
      CONNECTOR_STATUS_PREPARING,
      CONNECTOR_STATUS_CHARGING,
      CONNECTOR_STATUS_SUSPENDEDEV,
      CONNECTOR_STATUS_SUSPENDEDEVSE,
      CONNECTOR_STATUS_FINISHING,
      CONNECTOR_STATUS_RESERVED,
    ],
  },
  [STATUS_NOT_ACTIVATED]: {
    label: STATUS_NOT_ACTIVATED,
    color: theme.palette.customColors.statuses.grey,
    icon: <OccupiedIcon />,
    connectorStatuses: [],
  },
  [STATUS_ERROR]: {
    label: STATUS_ERROR,
    icon: <ErrorIcon />,
    color: theme.palette.customColors.statuses.red,
    connectorStatuses: [CONNECTOR_STATUS_FAULTED, CONNECTOR_STATUS_ERROR],
  },
  [STATUS_OFFLINE]: {
    label: STATUS_OFFLINE,
    color: theme.palette.common.black,
    icon: <OfflineIcon />,
    connectorStatuses: [CONNECTOR_STATUS_OFFLINE, CONNECTOR_STATUS_UNAVAILABLE],
  },
};

export const stationAnalyticsObject = {
  availableHours: {
    label: 'Available',
    color: theme.palette.customColors.statuses.green,
  },
  chargingHours: {
    label: 'Charging',
    color: theme.palette.customColors.statuses.blue,
  },
  occupiedNotChargingHours: {
    label: 'Occupied',
    color: theme.palette.customColors.statuses.yellow,
  },
  // errorHours: {
  //   label: 'Error',
  //   color: theme.palette.customColors.statuses.red,
  // },
  // offlineHours: {
  //   label: 'Offline',
  //   color: theme.palette.common.black,
  // },
};

export const getStationIconByStatus = (status = STATUS_AVAILABLE) => {
  const secureStatus = getSecureStatus(status);
  return stationStatusesObject[secureStatus].icon;
};

export const getStationColorByStatus = (status = STATUS_AVAILABLE) => {
  const secureStatus = getSecureStatus(status);

  return stationStatusesObject[secureStatus].color;
};

const getSecureStatus = (status) => {
  let secureStatus = status === STATUS_UNAVAILABLE ? STATUS_OFFLINE : status;
  if (!status) {
    secureStatus = STATUS_OFFLINE;
  }
  return secureStatus;
};

export const getStationStatusFromList = (stations) => {
  let status = STATUS_ERROR;

  for (let j = 0; j < stations.length; j++) {
    const station = stations[j];

    if (station.status === STATUS_AVAILABLE) {
      status = STATUS_AVAILABLE;
      break;
    }
    if (station.status === STATUS_IN_USE && status !== STATUS_AVAILABLE) {
      status = STATUS_IN_USE;
    }
    if (station.status === STATUS_OFFLINE && status !== STATUS_AVAILABLE && status !== STATUS_IN_USE) {
      status = STATUS_OFFLINE;
    }
  }

  return status;
};

export const checkIfStationWithPrice = (station) => {
  if (!station?.connectors?.length) {
    return false;
  }

  let isWithPrice = false;

  for (let i = 0; i < station.connectors.length; i++) {
    const connector = station.connectors[i];
    if (connector?.pricingProfileId) {
      isWithPrice = true;
      break;
    }
  }
  return isWithPrice;
};

export const getConnectorStatuesByStationStatus = (status) => {
  const secureStatus = getSecureStatus(status);
  return stationStatusesObject[secureStatus].connectorStatuses;
};

export const getChargingTypeFullText = (details) => {
  let chargingType = details?.chargingType ?? '';
  const isMonthlyPayment = details?.monthlyPayment;

  if (chargingType === 'public') {
    chargingType = capitalizeFirstLetter(chargingType);
  }
  if (chargingType === 'private') {
    chargingType = `${capitalizeFirstLetter(chargingType)} - ${isMonthlyPayment ? 'monthly payment' : 'adhoc payment'}`;
  }

  return chargingType;
};

export const canStationBeReserve = (station) => {
  if (station.details?.manufacturer === 'ABB') {
    return false;
  }

  return true;
};

export const isStationInChargingGroups = (station, chargingGroups) => {
  const stationIds = [];
  chargingGroups.forEach((group) => {
    group.stations.forEach((id) => {
      if (!stationIds.includes(id)) {
        stationIds.push(id);
      }
    });
  });
  return stationIds.includes(station.id);
};

export const isStationActivated = (station) => {
  return station.status !== STATUS_NOT_ACTIVATED;
};

export const getStationStatusLabel = (status) => {
  return `station.status.${status}`;
};
