import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { IManufacturerModel, IModelBasic, IStationModel, ReduxStatusEnum } from 'types';
import { getModelNameFromObject } from '../../../utils/models/stationModel';

interface IState {
  models: IModelBasic[];
  manufacturersAndModels: IManufacturerModel[];
  model: IModelBasic;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IState = {
  models: [],
  manufacturersAndModels: [],
  model: {} as IModelBasic,
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchModels = createAsyncThunk<IModelBasic[], { manufacturerName: string }>(
  'models/fetchModels',
  async ({ manufacturerName }) => {
    const response = await axiosRequest.get(
      `/stationSpecs/modelsByManufacturer/${encodeURIComponent(manufacturerName)}`
    );

    return response.data.map((item: string) => {
      return {
        id: `${item}`,
        name: item,
      };
    });
  }
);

export const fetchManufacturersAndModels = createAsyncThunk<IManufacturerModel[]>(
  'models/fetchManufacturersAndModels',
  async () => {
    const response = await axiosRequest.get(`/stationSpecs/manufacturers-and-models`);
    return response.data.map((item: IManufacturerModel) => ({
      ...item,
      id: getModelNameFromObject(item),
    }));
  }
);

export const createModel = createAsyncThunk<
  IStationModel,
  {
    details: IStationModel['details'];
    network: {
      wiFiSupported: IStationModel['wiFiSupported'];
      gsmSupported: IStationModel['gsmSupported'];
    };
    image: IStationModel['image'];
    connectors: IStationModel['connectors'];
    onSuccessCallback?: () => void;
    onErrorCallback?: () => void;
  }
>(
  'models/createModel',
  async ({ details, network, image, connectors, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const body = {
        ...details,
        maxCurrent: undefined,
        ...network,
        image,
        connectors,
        details: {
          maxCurrent: details.maxCurrent,
        },
      };

      const response = await axiosRequest.post('/stationSpecs', body);

      onSuccessCallback && onSuccessCallback();

      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

export const updateModel = createAsyncThunk<
  IStationModel,
  {
    id: string;
    details: IStationModel['details'];
    network: {
      wiFiSupported: IStationModel['wiFiSupported'];
      gsmSupported: IStationModel['gsmSupported'];
    };
    image: IStationModel['image'];
    connectors: IStationModel['connectors'];
    onSuccessCallback?: () => void;
    onErrorCallback?: () => void;
  }
>(
  'models/updateModel',
  async ({ id, details, network, image, connectors, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const body = {
        ...details,
        ...network,
        maxCurrent: undefined,
        image,
        connectors,
        details: {
          maxCurrent: details.maxCurrent,
        },
      };

      const response = await axiosRequest.put(`/stationSpecs/${id}`, body);

      onSuccessCallback && onSuccessCallback();

      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

const modelsSlice = createSlice({
  name: 'models',
  initialState: { ...initialState },
  reducers: {
    selectModel: (state, action: PayloadAction<{ name: string }>) => {
      state.model = {
        id: action.payload.name,
        name: action.payload.name,
      };
    },
    resetModel: (state) => {
      state.model = {} as IModelBasic;
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch models
      .addCase(fetchModels.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchModels.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.models = action.payload;
      })
      .addCase(fetchModels.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // fetchManufacturersAndModels
      .addCase(fetchManufacturersAndModels.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchManufacturersAndModels.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.manufacturersAndModels = action.payload;
      })
      .addCase(fetchManufacturersAndModels.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // create model
      .addCase(createModel.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(createModel.fulfilled, (state) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(createModel.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update model
      .addCase(updateModel.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updateModel.fulfilled, (state) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(updateModel.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { selectModel, resetModel } = modelsSlice.actions;

export default modelsSlice.reducer;
