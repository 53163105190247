import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { hideAlertModal } from 'reduxApp/slices/ui/toastAlertSlice';
import { theme } from 'themes/defaultTheme';

const width = 360;

export function ToastAlert({}) {
  const { isVisible, type = 'error', message } = useSelector((state) => state.toastAlert);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(100);
  const timerRef = useRef();

  useEffect(() => {
    setProgress(100);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (isVisible) {
      timerRef.current = setInterval(
        () => {
          setProgress((oldProgress) => {
            if (oldProgress <= 0) {
              clearInterval(timerRef.current);
            }
            return oldProgress - 1;
          });
        },
        type === 'error' ? 100 : 30
      );

      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }

    return () => null;
  }, [isVisible]);

  useEffect(() => {
    if (progress <= 0) {
      dispatch(hideAlertModal({}));
    }
  }, [progress]);

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: theme.zIndex.modal + 1,
        top: 50,
        left: `calc(50% - ${width / 2}px)`,
      }}
    >
      <Box sx={{ position: 'relative' }} boxShadow={27}>
        <Alert
          severity={type}
          sx={{
            width,
            pb: 1,
          }}
          onClose={() => {
            dispatch(hideAlertModal({}));
          }}
        >
          <Box sx={{ pb: 1 }}>{message}</Box>
        </Alert>
      </Box>
    </Box>
  );
}
