import { Box, InputLabel, Typography } from '@mui/material';
import * as React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'themes/defaultTheme';
import { InfoTooltip } from 'components/common/InfoTooltip';
import { InputTextRequiredOrOptional } from 'components/common/input/InputTextRequiredOrOptional';
import { getInputBoxStyle, getInputValueStyle, getLabelStyle } from 'components/utils';
import { defaultStyling } from 'types/styling';
import { ItemLabelValue } from '../ItemLabelValue';
import { BasicInput } from './BasicInput';

export function CustomInputText({
  label,
  placeholder = '',
  value,
  setValue,
  validation,
  valueDirty,
  setValueDirty,
  sx = {},
  type = 'text',
  disabled = false,
  showDisabledWithoutOpacity = false,
  inputProps = null,
  extraError = false,
  setExtraError = undefined,
  extraErrorMessage = undefined,
  isSuccess = false,
  containerSx = {},
  infoTooltip = null,
  showCloseButton = false,
  onEnterPress = () => null,
  labelSize = 'lg',
  validText = '',
  styling = { ...defaultStyling },
  showRequired = true,
  ...data
}) {
  const extraProps = { ...data };

  if (type === 'numeric') {
    extraProps.inputProps = { inputMode: 'numeric', pattern: '[0-9]|(.)*' };
  }

  // if (type === 'password') {
  //   extraProps.inputProps = { inputMode: 'numeric', pattern: '[0-9]|(.)*' };
  // }

  if (inputProps) {
    extraProps.inputProps = { ...extraProps.inputProps, ...inputProps };
  }

  if (setValueDirty) {
    extraProps.onBlur = () => setValueDirty(true);
  }

  const onInputChange = (e) => {
    setExtraError && setExtraError(false);
    setValue(e);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnterPress();
    }
  };

  if (disabled && showDisabledWithoutOpacity) {
    return <ItemLabelValue label={label} value={value} size={labelSize} styling={styling} />;
  }

  const { isFlexRow } = styling;

  return (
    <Box sx={[getInputBoxStyle(isFlexRow), containerSx]}>
      {label?.length > 0 && (
        <InputLabel sx={getLabelStyle(isFlexRow, labelSize, styling?.labelCustomStyle ?? {})}>
          {label}
          {!disabled && showRequired && <InputTextRequiredOrOptional required={validation} />}
          {infoTooltip && <InfoTooltip tooltipComponent={infoTooltip} />}
        </InputLabel>
      )}

      <Box sx={[getInputValueStyle(isFlexRow), { position: 'relative' }]}>
        <BasicInput
          disabled={disabled}
          placeholder={placeholder}
          value={value ?? ''}
          setValue={setValue}
          valueDirty={valueDirty}
          setValueDirty={setValueDirty}
          validation={validation}
          extraError={extraError}
          setExtraError={setExtraError}
          extraErrorMessage={extraErrorMessage}
          type={type}
          onEnterPress={onEnterPress}
          sx={sx}
          inputProps={extraProps.inputProps}
          onKeyDown={onKeyDown}
        />

        {value?.length > 0 && showCloseButton && (
          <Box
            onClick={() => onInputChange({ target: { value: '' } })}
            sx={{
              fontSize: theme.components.MuiOutlinedInput.styleOverrides.input.fontSize,
              cursor: 'pointer',
              position: 'absolute',
              right: 8,
              top: 2,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon />
          </Box>
        )}

        {isSuccess && (
          <Box sx={{ position: 'absolute', right: -32, top: 2, bottom: 0, display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon sx={{ fontSize: 24, color: '#66bb6a' }} />
          </Box>
        )}

        {validation?.isValid && !!validText?.length && (
          <Typography variant="caption" sx={{ color: theme.palette.text.secondary, mt: 0 }}>
            {validText}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
