import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cellStatus, logsStations } from 'utils/DataGrid';
import { stringCompare } from 'utils/helpers';

export const useStationLogColumn = () => {
  const { t } = useTranslation();
  const allUniqueLogsStatuses = useMemo<string[]>(
    () =>
      Object.values(logsStations)
        .map((item) => {
          return t(item.name);
        })
        .sort(stringCompare),
    []
  );

  const statusColumn = {
    accessorKey: 'status',
    accessorFn: (row: any) => t(`station.logs.status.${row?.status}`),
    header: t('global.header.status'),
    size: 120,
    Cell: ({ row, cell }: any) => cellStatus(logsStations, row.original.status),
    filterVariant: 'multi-select',
    filterSelectOptions: allUniqueLogsStatuses,
  };

  return {
    statusColumn,
  };
};
