import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchClientUser = createAsyncThunk(
  'clientUser/fetchClientUser',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/users/${userId}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  'clientUser/updateUserPassword',
  async ({ password, onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/users/password`, { password });
      onSuccessCallback && onSuccessCallback();

      return {};
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateUser = createAsyncThunk(
  'clientUser/updateUser',
  async (
    { userId, lastName, firstName, phone, email, clientId, clientName, onSuccessCallback },
    { rejectWithValue }
  ) => {
    try {
      await axiosRequest.put(`/users/${userId}`, {
        firstName,
        lastName,
        email,
        details: { phone },
        clientId,
      });
      onSuccessCallback && onSuccessCallback();

      return {
        lastName,
        firstName,
        clientId,
        clientName,
        details: { phone },
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createUser = createAsyncThunk(
  'clientUser/createUser',
  async ({ email, lastName, firstName, phone, clientId = '', onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.post(`/users/`, {
        email,
        lastName,
        firstName,
        clientId,
        role: 'admin',
        details: { phone },
        sendEmail: true,
      });
      onSuccessCallback && onSuccessCallback();
      return {
        lastName,
        firstName,
        details: { phone },
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clientUserSlice = createSlice({
  name: 'clientUser',
  initialState: {
    user: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    resetClientUser: (state) => {
      state.user = {};
      state.status = STATUS_IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientUser.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchClientUser.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(fetchClientUser.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // updatePassword
      .addCase(updateUserPassword.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update user
      .addCase(updateUser.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // create user
      .addCase(createUser.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetClientUser } = clientUserSlice.actions;

export default clientUserSlice.reducer;
