import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../api/axiosRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from '../../../utils';

export const fetchStations = createAsyncThunk(
  'stations/fetchStations',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/stations${queryParameters}`)
        : await axiosRequest.get('/stations');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clearStations = createAsyncThunk('stations/clearStations', async (_, { rejectWithValue }) => {
  return [];
});

export const stationsSlice = createSlice({
  name: 'stations',
  initialState: {
    stations: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // fetch stations
      .addCase(fetchStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stations = action.payload;
      })
      .addCase(fetchStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // clear stations
      .addCase(clearStations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(clearStations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stations = action.payload;
      })
      .addCase(clearStations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationsSlice.reducer;
