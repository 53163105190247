import { DAYS_FOR_API } from '../datetime';

const CHARGING_PROFILE_STATE = [
  {
    index: 0,
    periods: [
      {
        limit: '32',
        startPeriod: '00:00 am',
      },
    ],
  },
  {
    index: 1,
    periods: [
      {
        limit: '32',
        startPeriod: '00:00 am',
      },
    ],
  },
  {
    index: 2,
    periods: [
      {
        limit: '32',
        startPeriod: '00:00 am',
      },
    ],
  },
  {
    index: 3,
    periods: [
      {
        limit: '32',
        startPeriod: '00:00 am',
      },
    ],
  },
  {
    index: 4,
    periods: [
      {
        limit: '32',
        startPeriod: '00:00 am',
      },
    ],
  },
  {
    index: 5,
    periods: [
      {
        limit: '32',
        startPeriod: '00:00 am',
      },
    ],
  },
  {
    index: 6,
    periods: [
      {
        limit: '32',
        startPeriod: '00:00 am',
      },
    ],
  },
];

const mapDayToIndex = (day) => {
  switch (day) {
    case 'mon':
      return 0;
    case 'tue':
      return 1;
    case 'wed':
      return 2;
    case 'thu':
      return 3;
    case 'fri':
      return 4;
    case 'sat':
      return 5;
    case 'sun':
      return 6;
    default:
      return 0;
  }
};

export const getChargingProfileInitialState = () => {
  return CHARGING_PROFILE_STATE;
};

export const getDefaultChargingProfilePeriod = () => {
  return {
    limit: '32',
    startPeriod: '00:30 am',
  };
};

export const convertChargingProfileToApi = (chargingSchedulePeriod) => {
  const days = {};

  chargingSchedulePeriod.forEach((schedule, index) => {
    days[DAYS_FOR_API[index]] = schedule.periods.map((period) => {
      return {
        limit: period.limit,
        start: period.startPeriod,
      };
    });
  });

  return days;
};

export const convertChargingProfileFromApi = (chargingSchedulePeriodApi) => {
  const days = [...CHARGING_PROFILE_STATE].map((item) => {
    return { ...item };
  });

  Object.keys(chargingSchedulePeriodApi).forEach((dayKey) => {
    if (DAYS_FOR_API.includes(dayKey)) {
      const index = mapDayToIndex(dayKey);
      const periods = chargingSchedulePeriodApi[dayKey].map((item) => {
        return {
          limit: item.limit,
          startPeriod: item.start,
        };
      });
      days[index].periods = [...periods];
    }
  });

  return days;
};
