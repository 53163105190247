import { createSlice } from '@reduxjs/toolkit';
import { getConnectorInitialState } from 'utils/models/connector';
import { IStationModel, IStationModelStep } from 'types';

const initialState: IStationModelStep = {
  details: {
    manufacturer: '',
    model: '',
  },
  connectors: [{ ...getConnectorInitialState(true) }] as IStationModel['connectors'],
  image: null,
  activeStep: 0,
  isUpdated: false,
  isNewManufacturer: false,
  id: null,
};

const createModelStepSlice = createSlice({
  name: 'createModelStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setToUpdateModel: (state, action) => {
      state.data.isUpdated = true;
      state.data.id = action.payload.id;
    },
    setDetailsModelStep: (state, action) => {
      state.data.details = {
        ...state.data.details,
        ...action.payload,
      };
    },
    setIsNewManufacturerModelStep: (state, action) => {
      state.data.isNewManufacturer = action.payload;
    },
    setNetworkModelStep: (state, action) => {
      state.data.networkDetails = {
        ...state.data.networkDetails,
        ...action.payload,
      };
    },
    setConnectorModelStep: (state, action) => {
      state.data.connectors = action.payload;
    },
    setImageCreateModelStep: (state, action) => {
      state.data.image = action.payload.image;
    },
    resetModelCreateStep: (state) => {
      state.data = initialState;
    },
    increaseModelCreateStep: (state) => {
      const stepSize = 1;
      state.data.activeStep += stepSize;
    },
    decreaseModelCreateStep: (state) => {
      const stepSize = 1;
      state.data.activeStep -= stepSize;
    },
  },
});

export const {
  decreaseModelCreateStep,
  increaseModelCreateStep,
  resetModelCreateStep,
  setConnectorModelStep,
  setDetailsModelStep,
  setNetworkModelStep,
  setImageCreateModelStep,
  setToUpdateModel,
  setIsNewManufacturerModelStep,
} = createModelStepSlice.actions;

export default createModelStepSlice.reducer;
