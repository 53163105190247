import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hideWindowLoader } from 'reduxApp/slices/ui/windowLoaderSlice';

function WindowLoader() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isVisible } = useSelector((state) => state.windowLoader);

  useEffect(() => {
    dispatch(hideWindowLoader());
  }, [location]);

  if (!isVisible) {
    return null;
  }

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 2 }} open={isVisible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default WindowLoader;
