import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChargingProfileInitialState } from 'utils/models/chargingProfile';
import { axiosRequest } from 'api/axiosRequest';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { hideWindowLoader, showWindowLoader } from '../ui/windowLoaderSlice';

const initialState = {
  name: '',
  chargingSchedulePeriod: getChargingProfileInitialState(),
};

export const fetchChargingProfile = createAsyncThunk(
  'chargingProfile/fetch',
  async ({ chargingProfileId }, thunkApi) => {
    try {
      const response = await axiosRequest.get(`/chargingprofiles/${chargingProfileId}`);
      return { data: response.data };
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const createChargingProfile = createAsyncThunk(
  'chargingProfile/create',
  async ({ name, days, onSuccessCallback }, thunkApi) => {
    try {
      thunkApi.dispatch(showWindowLoader());
      const response = await axiosRequest.post(`/chargingprofiles`, {
        name,
        ...days,
      });
      onSuccessCallback && onSuccessCallback();
      thunkApi.dispatch(hideWindowLoader());

      return { data: response.data };
    } catch (e) {
      thunkApi.dispatch(hideWindowLoader());
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const updateChargingProfile = createAsyncThunk(
  'chargingProfile/update',
  async ({ chargingProfileId, name, days, onSuccessCallback }, thunkApi) => {
    try {
      thunkApi.dispatch(showWindowLoader());
      const response = await axiosRequest.put(`/chargingprofiles/${chargingProfileId}`, {
        name,
        ...days,
      });
      onSuccessCallback && onSuccessCallback();
      thunkApi.dispatch(hideWindowLoader());

      return { name, ...days };
    } catch (e) {
      thunkApi.dispatch(hideWindowLoader());
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const chargingProfileSlice = createSlice({
  name: 'chargingProfile',
  initialState: {
    chargingProfile: initialState,
    chargingProfileApi: {},
  },
  reducers: {
    resetChargingProfile: (state) => {
      state.chargingProfile = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // create
      .addCase(createChargingProfile.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createChargingProfile.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(createChargingProfile.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update
      .addCase(updateChargingProfile.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateChargingProfile.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.chargingProfileApi = { ...state.chargingProfileApi, ...action.payload };
      })
      .addCase(updateChargingProfile.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // fetch
      .addCase(fetchChargingProfile.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchChargingProfile.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.chargingProfileApi = { ...action.payload.data };
      })
      .addCase(fetchChargingProfile.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetChargingProfile } = chargingProfileSlice.actions;

export default chargingProfileSlice.reducer;
