import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cellStatusError, logsStations, showLogDetails } from 'utils/DataGrid';
import { stringCompare } from 'utils/helpers';

export const useStationErrorLogColumn = (errorLogs: any) => {
  const { t } = useTranslation();

  const getErrorStatus = (message: any): string => {
    const obj = JSON.parse(showLogDetails(message));
    return obj?.['Central System message']?.idTagInfo?.status || obj?.status || 'Error';
  };

  const allUniqueLogsStatuses = useMemo<any[]>(
    () => [...new Set(errorLogs.map((item: any) => getErrorStatus(item?.details)))].sort(stringCompare),
    [errorLogs]
  );

  const statusColumn = {
    accessorKey: 'status',
    accessorFn: (row: any) => getErrorStatus(row?.details),
    header: t('global.header.status'),
    Cell: ({ renderedCellValue }: any) => cellStatusError(renderedCellValue),
    filterVariant: 'multi-select',
    filterSelectOptions: allUniqueLogsStatuses,
  };

  return {
    statusColumn,
  };
};
