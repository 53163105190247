import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cardDetails: {
    contractId: '',
    name: '',
    clientId: '',
    groupId: '',
    client: {
      id: '',
      label: '',
    },
  },
};

export const registerCardStepSlice = createSlice({
  name: 'registerCardStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setCardDetails: (state, action) => {
      state.data.cardDetails = {
        ...state.data.cardDetails,
        ...action.payload,
      };
    },

    resetRegisterCardStep: (state) => {
      state.data = initialState;
    },
  },
});

export const { setCardDetails, resetRegisterCardStep } = registerCardStepSlice.actions;

export default registerCardStepSlice.reducer;
