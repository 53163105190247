import { ChargingTypeEnum, CreateStationStepState, ICurrency } from 'types';
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { locationInitialState } from '../../../utils/models/location';

const initialState: CreateStationStepState = {
  stationDetails: {
    id: '',
    securityCode: '',
    serialNumber: '',
    manufacturer: '',
    articleNumber: {
      id: '',
      name: '',
    },
    model: '',
    name: '',
    clientId: '',
    client: {
      id: '',
      label: '',
    },
  },
  location: { ...locationInitialState },
  locationTab: 0,
  pricingDetails: {
    chargingType: ChargingTypeEnum.public,
    monthlyPayment: undefined,
    prices: [
      {
        currency: ICurrency.RON,
      },
    ],
    connectors: [],
  },
  billingPlan: {},
  activeStep: 0,
};

export const createStationStepSlice = createSlice({
  name: 'createStationStep',
  initialState: {
    data: { ...initialState },
  },
  reducers: {
    setStationDetails: (state, action: PayloadAction<Partial<CreateStationStepState['stationDetails']>>) => {
      state.data.stationDetails = {
        ...state.data.stationDetails,
        ...action.payload,
      };
    },
    setLocation: (state, action: PayloadAction<CreateStationStepState['location']>) => {
      state.data.location = action.payload;
    },
    setLocationTab: (state, action: PayloadAction<CreateStationStepState['locationTab']>) => {
      state.data.locationTab = action.payload;
    },
    setPricingDetails: (state, action: PayloadAction<CreateStationStepState['pricingDetails']>) => {
      state.data.pricingDetails = action.payload;
    },
    resetCreateStationStep: (state) => {
      state.data = initialState;
    },
    increaseCreateStationStep: (state) => {
      state.data.activeStep += 1;
    },
    decreaseCreateStationStep: (state) => {
      state.data.activeStep -= 1;
    },
  },
});

export const {
  setStationDetails,
  setLocation,
  setPricingDetails,
  setLocationTab,
  increaseCreateStationStep,
  decreaseCreateStationStep,
  resetCreateStationStep,
} = createStationStepSlice.actions;

export default createStationStepSlice.reducer;
